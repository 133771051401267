.singleRest {
  .singleRestHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 137px;
    padding-bottom: 144px;
    h2 {
      color: #fff;
      text-align: center;
      font-size: 80px;
      font-weight: 900;
      line-height: 85px; /* 106.25% */
      text-transform: uppercase;
    }
    .singleRestLocation {
      display: flex;
      flex-direction: row;
      gap: 23px;
      color: #fff;
      margin-bottom: 60px;
      span {
        text-align: center;
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 28px;
        font-weight: 600;
        line-height: 45px; /* 160.714% */
        text-transform: capitalize;
      }
    }
    .singleRestHeadButtons {
      display: flex;
      flex-direction: row;
      gap: 46px;
      button {
        border-radius: 100%;
        padding: 26px;
        color: #941c1f;
      }
    }
  }
  .singleRestInfo {
    padding: 44px 170px 88px;
    .singleRestInfoTop {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      h2 {
        color: #000;
        font-size: 60px;
        font-weight: 900;
        line-height: 85px; /* 141.667% */
        text-transform: uppercase;
      }
      button {
        border-radius: 30px;
        background: linear-gradient(90deg, #2b5876 0%, #4e4376 100%);
        height: 61px;
        padding: 17px 65px;
        color: #fff;
        display: flex;
        justify-content: center;
        border: none;
        font-size: 18px;
        font-weight: 600;
      }
    }
    .contentSingleRest {
      color: #555;
      font-family: Poppins;
      font-size: 12px;
      margin-top: 20px;
    }
  }
  .singleRestInPageNav {
    background: #9b763b;
    padding: 44px 110px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 40px;
    button {
      border-radius: 20px;
      background: #fff;
      border: none;
      padding-top: 23px;
      padding-bottom: 23px;
    }
    .active {
      background: #cca464;
      color: white;
    }
  }
  .singleRestDrinksSection {
    padding: 65px 209px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    article {
      border-radius: 10px;
      background: #fff;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: center;
      align-items: center;
      padding: 27px 0;
      span {
        font-size: 18px;
        font-weight: 500;
      }
      small {
        color: #848484;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
  .singleRestFoot {
    padding: 83px 121px;
    h2 {
      color: #fff;
      font-size: 60px;
      font-weight: 900;
      line-height: 65px; /* 108.333% */
      text-transform: capitalize;
      span {
        color: #b48d4f;
      }
    }
    .footBottom {
      margin-top: 36px;
      display: flex;
      flex-direction: row;
      gap: 24px;
      span {
        color: #fff;
        font-size: 30px;
        font-weight: 900;
        line-height: 65px; /* 216.667% */
        text-transform: capitalize;
      }
      div {
        border-radius: 15px;
        background: #b48d4f;
        padding: 6px 34px;
        color: #fff;
        font-size: 40px;
        font-weight: 900;
        line-height: 65px; /* 162.5% */
        text-transform: capitalize;
      }
    }
  }
}
