.eventRest {
  padding: 14px 16px 56px 16px;
  border-radius: 10px;
  background: #fff;
  .topNav {
    margin-bottom: 36px;
    border-radius: 10px;
    background: #fafafa;
    display: flex;
    flex-direction: row;
    align-items: center;
    h1 {
      margin-top: 6px;
      margin-left: 6px;
      color: #555;
      font-size: 14px;
      font-weight: 500;
      border-radius: 8px;
      background: #fff;
      padding: 6px 16px;
    }
  }
}
