.reset-password-page--form {
  .form-control {
    &:focus {
      border-color: #245177;
    }
  }
}

.password-toggle {
  @apply tw--mt-1;
}
