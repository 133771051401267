#account-dropdown-menu {
  margin: 0;
  margin-top: 4px;
  padding: 0;
  border: 0;

  .account-dropdown-menu-item {
    margin: 0;
    padding: 0;
  }
}

.dropdown-toggle::after {
  display: none !important;
}
