.eventDashTestHeader {
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 25px 28px 23px 26px;
  .headerProfile {
    .headerProfiledetails {
      margin-bottom: 29px;
      h2 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 0 !important;
      }
      span {
        color: #999;
        font-size: 10px;
        margin-top: 0 !important;
      }
    }
    hr {
      width: 430px;
      height: 1px;
      margin-bottom: 36px;
    }
    .headerProfileUser {
      display: flex;
      flex-direction: row;
      gap: 28px;
      .nameAndOD {
        display: flex;
        flex-direction: column;
        justify-content: center;
        h2 {
          font-size: 21px;
        }
        .eventLoc {
          .headerLocation {
            p {
              color: #999;
              font-size: 10px;
            }
          }
        }
        .eventTypes {
          display: flex;
          flex-direction: row;
          gap: 7px;
          span {
            color: #848484;
            font-size: 7px;
            padding: 2px 14px;
            border-radius: 3px;
            background: #e9e9e9;
          }
        }
        .eventSocials {
          margin-top: 16px;
          display: flex;
          flex-direction: row;
          gap: 15px;
        }
      }
    }
  }
  .headerOptions {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-right: 28px;
    .uAbtn {
      padding: 10px 16px;
      width: 120px;
      margin-left: auto;
      border-radius: 20px;
      border: 2px solid #d9d9d9;
      background: #fff;
      color: #778389;
      font-size: 10px;
    }
    .headerWallet {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 18px 24px;
      border-radius: 10px;
      background: linear-gradient(90deg, #536976 0%, #292e49 100%);
      span {
        padding: 7px 20px;
        border-radius: 8px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.2) 0%,
          rgba(255, 255, 255, 0.06) 100%
        );
        color: #fff;
        font-size: 12px;
        width: 136px;
        font-weight: 500;
        margin-bottom: 19px;
      }
      small {
        color: #fff;
        font-size: 10px;
        font-weight: 500;
        margin-top: 7px;
      }
      p {
        color: #fff;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 9px;
      }
      .walletBalance {
        color: #555;
        margin-top: 9px;
        font-size: 10px;
        padding: 8px 30px;
        border-radius: 5px;
        background: #fff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
      }
      a {
        color: #fff;
        margin-top: 17px;
        font-size: 10px;
        font-weight: 500;
        display: flex;
        flex-direction: row;
        div {
          margin-left: 4px;
        }
      }
    }
  }
}
