.restCard {
  .restCardInd {
    padding: 20px 20px 10px 20px;
    height: 470px;
    width: 97%;
    max-width: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    color: white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    position: relative;
    text-align: center;
    @media (max-width: 1000px) {
      height: 450px;
      padding-bottom: 80px;
    }
    @media (max-width: 600px) {
      height: 400px;
      padding-bottom: 60px;
    }
    .star {
      position: absolute;
      width: 70px;
      height: 30px;
      right: 15px;
      top: 15px;
      background: #ffffff;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 3px;
      color: #555555;
      font-weight: 500;
      font-size: 16px;
    }
    .name {
      text-transform: uppercase;
      font-weight: 900;
      font-size: 30px;
      line-height: 40px;
      color: white;
      letter-spacing: 1px;
    }
    .btn-discoverRest {
      border-radius: 10px;
      background: #9b763b;
      padding: 18px 66px;
      color: #fff;
      font-size: 22px;
      font-weight: 600;
      &:hover {
        background: #9c874a;
      }
    }
    .address {
      font-weight: 600;
      font-size: 17px;
      color: white;
    }
  }
}
