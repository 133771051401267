@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "assets/scss/bootstrap-variables";
@import "~bootstrap/scss/bootstrap";
@import "assets/scss/toastify";
@import "assets/scss/react-datepicker";
@import "assets/scss/custom";

@layer base {
  :root {
    font-family: Poppins, sans-serif, Inter, system-ui, Avenir, Helvetica, Arial;

    font-size: 13px;

    // --background: 0 0% 100%;
    // --foreground: 222.2 47.4% 11.2%;

    // --muted: 210 40% 96.1%;
    // --muted-foreground: 215.4 16.3% 46.9%;

    // --popover: 0 0% 100%;
    // --popover-foreground: 222.2 47.4% 11.2%;

    // --border: 214.3 31.8% 91.4%;
    // --input: 214.3 31.8% 91.4%;

    // --card: 0 0% 100%;
    // --card-foreground: 222.2 47.4% 11.2%;

    // --primary: 222.2 47.4% 11.2%;
    // --primary-foreground: 210 40% 98%;

    // --secondary: 210 40% 96.1%;
    // --secondary-foreground: 222.2 47.4% 11.2%;

    // --accent: 210 40% 96.1%;
    // --accent-foreground: 222.2 47.4% 11.2%;

    // --destructive: 0 100% 50%;
    // --destructive-foreground: 210 40% 98%;

    // --ring: 215 20.2% 65.1%;

    // --radius: 0.5rem;

    // transition-property: all;
    // transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    // transition-duration: 150ms;

    @media only screen and (max-width: 600px) {
      font-size: 14px;
    }
  }
}
.add-bank-modal {
  background: linear-gradient(90deg, #941c1f 0%, #205076 100%);
}
.sidebar .submenu {
  display: none;
}

.sidebar .submenu.open {
  display: block;
}

.tw-input {
  @apply tw-flex tw-h-fit tw-min-h-[3.5rem] tw-w-full tw-rounded-xl tw-border tw-px-4 tw-py-2 tw-text-sm  file:tw-border-0 file:tw-bg-transparent file:tw-text-sm file:tw-font-medium focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-ring-[hsl(215_20.2%_65.1%)] focus-visible:tw-ring-offset-0 disabled:tw-cursor-not-allowed disabled:tw-opacity-50 tw-transition-all;
}

.tw-radio {
  @apply tw-w-6 tw-h-6 tw-text-secondary-600 tw-bg-gray-100 tw-border-gray-300 focus:tw-ring-secondary-500 dark:focus:tw-ring-secondary-600 dark:tw-ring-offset-gray-800 focus:tw-ring-2 dark:tw-bg-gray-700 dark:tw-border-gray-600;
}

.tw-transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}

.shake-animation {
  animation: shake 0.3s ease-in-out;
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
}
