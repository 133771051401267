.share-div {
  visibility: hidden;
  width: 100%;
  max-width: 500px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 8px;
  position: absolute;
  z-index: 1;
  &.show {
    visibility: visible;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
  }
  // &::after {
  //   content: "";
  //   position: absolute;
  //   top: 100%;
  //   left: 37%;
  //   border-width: 6px;
  //   border-style: solid;
  //   border-color: #555 transparent transparent transparent;
  // }
  .social-icons {
    background-color: #fff;
    margin-right: 10px;
    box-shadow: 0 0 5px 0 #ccc;
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50px;
    font-size: 18px;
    color: #96141c;
    text-align: center;
    cursor: pointer;
    @media (max-width: 1100px) {
      width: 35px;
      height: 35px;
    }
  }
}
