.main-header-component {
  background-color: #ffffff;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 30%);
  // margin-bottom: 10px;
  padding: 0 20px;
  z-index: 9999;

  &.fixed-header {
    position: fixed;
    top: 0;
  }

  @media (max-width: 780px) {
    padding: 0;
  }

  & > .nav-max-width {
    max-width: 1650px;
  }

  .navbar-nav {
    & > .nav-item {
      display: flex;
      align-items: center;
      justify-content: center;

      & > .nav-link {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0.625rem 0;
      }

      .dropdown-toggle {
        &::after {
          display: none;
        }
      }

      .dropdown-menu {
        border-radius: 0;
        margin: -2px 0 0 0;

        & > .dropdown-item {
          min-width: 10rem;
          padding: 10px 20px;
          transition: all 100ms ease-in 10ms;
          color: #000;

          &:hover {
            color: #ffffff;
            background-color: #96141c;
          }
        }
      }
    }
  }

  .search-input {
    input[name="nameSearch"],
    .input-group-text {
      border: solid 1px #ced4da;
      font-size: 0.875rem;
    }
  }

  .search-dropdown {
    top: 100%;
    z-index: 9999;
    box-shadow: 0 0 0.2rem 0 rgb(0 0 0 / 30%);
    width: 100%;

    & > li:not(last-child) {
      border-top: solid 1px #f1f1f1;
    }

    & > li {
      transition: 200ms ease-in background-color;

      & > a {
        padding: 0.725rem;
      }

      &:hover {
        background-color: #f7f7f7;
      }
    }
  }

  .location-input-wrapper {
    width: 300px;

    .location-input {
      border-radius: 0;
      border: none;
      width: auto;
    }

    .search-dropdown {
      right: 0;
      width: 350px;
    }
  }
}
