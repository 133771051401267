.createEventDash {
  border-radius: 8px;
  background: linear-gradient(90deg, #614385 0%, #516395 100%);
  padding-top: 21px;
  padding-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    color: #fff;
    font-size: 12px;
    text-align: center;
  }
  button {
    border-radius: 50px;
    border: 2px solid #fff;
    background: transparent;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding: 13px 66px;
    &:hover {
      background: #fff;
      color: #614385;
      transition: transform 0.2s ease-in-out;
    }
  }
}
