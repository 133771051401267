#user-account-dropdown {
  border: 0px !important;
}

.nav-link-item {
  background-color: white !important;
  padding: 0 !important;
  margin: 0 !important;
  // :hover {
  //     @apply ;
  // }
}
