.footer {
  margin-top: auto;
  .main {
    position: relative;
    background-color: #fff;
    color: #555555;
    padding: 30px 0 10px;
    h4 {
      font-size: 20px;
      padding: 20px 0 0 0;
      color: #555555;
    }

    .logo {
      width: 193.86px;
      height: 79.48px;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 15px 0 0 0;

      & > li {
        padding: 6px 0;

        & > a {
          text-decoration: none;
          color: #555555;
          transition: linear color 100ms;
          transition-delay: 50ms;
          font-size: 16px;
          &:hover {
            color: rgba($color: #555555, $alpha: 0.8);
          }
        }
      }
    }

    .dropdown-menu {
      min-width: 400px;

      & > .dropdown-item {
        padding: 2.5px 8px;
        &:hover {
          background-color: transparent;
        }
      }
    }

    .location-language-wrapper {
      display: flex;
      align-items: center;
      font-size: 17px;
      column-gap: 10px;
      .label {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        display: flex;
        &.country {
          overflow: hidden;
          cursor: pointer;
        }
        &.currency {
          border: solid 2px #555555;
        }
      }

      .location-input {
        border-radius: 0;
        border: none;
        width: 89%;
      }
    }

    .social-icon-container {
      display: grid;
      grid-template-columns: repeat(4, 35px);
      grid-gap: 10px;

      @media (max-width: 600px) {
        width: 100vw;
        align-content: center;
        justify-content: center;
        margin: 20px 0;
      }

      .social-icon {
        color: #555555;
        font-size: 20px;
        transition: linear color 100ms;
        transition-delay: 50ms;
        &:hover {
          color: rgba($color: #555555, $alpha: 0.8);
        }
      }
    }

    .appstore-icons {
      padding: 20px 0;
      border-top: 1px solid #555555;

      & > img {
        cursor: pointer;
        width: 140px;
        margin: 10px;
      }
    }
  }

  .copyright {
    padding: 30px;
  }
  .pt-8 {
    padding-top: 80px;
  }
  .subscribe-wrapper {
    background-color: white;
    text-align: center;
    width: 860px;
    max-width: 100%;
    margin-inline: auto;
    position: absolute;
    top: -50px;
    left: 0;
    right: 0;
    padding: 10px 40px;
    border-radius: 50px;
    h5 {
      font-size: 16px;
    }
    input {
      border-radius: 20px;
      border: 0;
      padding-left: 20px;
      padding-top: 30px !important;
      padding-bottom: 30px !important;
    }
    .btn-sub {
      border-radius: 0px 30px 30px 0px;
      padding: 20px 47px;
      font-weight: 600;
      border: none;
      &:hover {
        background-color: rgba($color: #bd391f, $alpha: 0.9);
      }
      @media (max-width: 600px) {
        padding: 20px 25px;
      }
    }
    .inpt-ee {
      border-radius: 30px 0px 0px 30px;
      padding-left: 30px;
    }
  }
}
