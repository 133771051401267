.search-navbar-wrapper {
  #navbar-responsive {
    &.overlay {
      height: 100%;
      width: 0;
      position: fixed;
      z-index: 10;
      left: -32px;
      top: 0;
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.9);
      overflow-x: hidden;
      transition: 0.5s;
      a {
        padding: 5px 8px 10px 15px;
        text-decoration: none;
        font-size: 16px;
        color: #8e8e8e !important;
        transition: 0.3s;
        display: block;
        :hover {
          color: #dcdcdc;
        }
      }

      a:hover {
        color: #dcdcdc !important;
      }
      .closebtn {
        position: absolute;
        top: 0;
        right: 10px;
        font-size: 40px;
        display: inherit;
        margin: 0;
        height: 40px;
        vertical-align: text-top;
        padding: 0;
        width: 40px;
        text-align: center;
      }
    }

    .overlay-content {
      position: relative;
      top: 10%;
      width: 100%;
      padding-left: 10px;
      padding-bottom: 40px;
      text-align: left;
      margin-top: 30px;
    }

    .mobile-sidemenuheading {
      font-size: 12px;
      color: #dcdcdc;
      text-transform: uppercase;
      margin-bottom: 10px;
      padding: 5px 8px 0 15px;
    }
  }
  .search-input-container {
    height: "100%";
    margin: 0;
    background-color: transparent;
  }
  .search-btn {
    background: #16ae5b;
    border-radius: 0px 10px 10px 0px;
    padding: 12.5px 46px;
    height: 100%;
    &:hover {
      background-color: rgba($color: #0d7f42, $alpha: 0.9);
    }
  }
  .navbar-nav {
    column-gap: 34px;
    &,
    .dropdown-item {
      font-size: 14px;
    }
  }
}

.dropdown-menu {
  min-width: 300px;

  & > .dropdown-item {
    padding: 2.5px 8px;
    &:hover {
      background-color: transparent;
    }
  }
}

.ppp {
  padding-top: 10px;
}

.label {
  width: 21px;
  height: 21px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  &.country {
    overflow: hidden;
    cursor: pointer;
  }
}

.location-input {
  border-radius: 0;
  border: none;
  width: 89%;
}
