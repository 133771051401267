.notFound_page {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  img {
    width: 300px;
    object-fit: contain;
  }
  p {
    font-weight: 500;
    margin-bottom: 20px;
  }
}
