$font-family-base: "Poppins", sans-serif;
$font-weight-base: 300;
$font-weight-bold: 500;
$font-weight-bolder: 800;
$font-size-base: 0.875rem;
$headings-font-weight: 300;
$input-btn-focus-box-shadow: none;
$btn-active-box-shadow: none;
$btn-focus-box-shadow: none;
$btn-box-shadow: unset;
$btn-focus-width: 0;

$secondary: #235177;
$success: #029d02;

$link-color: #205177;

$box-shadow: 0 0 0.31rem 0 rgba(0, 0, 0, 0.3);

$form-group-margin-bottom: 1.2rem;
$input-btn-padding-y: 0.575rem;
$input-btn-padding-y-lg: 0.8rem;
$btn-font-size-lg: 0.8rem;
$btn-font-weight: 500;

$theme-colors: (
  "accent": #96141c,
  "attendee": #fc4d4d,
  "gray-30": #fafafa,
  "gray-50": #f5f5f5,
  "gray-60": #f9f9f9,
  "gray-70": #e9e9e9,
  "gray-100": #ced4da,
  "gray-150": #999999,
  "gray-180": #848484,
  "gray-200": #666666,
  "gray-300": #333333,
  "gray-400": #202020,
  "gray-500": #555555,
  "blue-dark": #205177,
  "green": #009d00,
  "green-30": #dbe692,
  "black": #000,
  "black-300": #323232,
  "red": red,
  "blue": #337ab7,
  "yellow": #ec7119,
  "yellow-100": #ffd480,
  "wafer": #e5d1d1,
  "white": #fff,
  "black-30": #1b1b1b,
);

$spacers: (
  "4_5": 2rem,
  6: 6rem,
  "neg_3": -0.6rem,
  "neg_4": -1.2rem,
  "neg_5": -1.5rem,
);

$font-sizes: (
  "xxs": 8px,
  "mxs": 10px,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1350px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1300px,
);
.container {
  @media (min-width: 1100px) and (max-width: 1190px) {
    max-width: 1000px !important;
  }
  @media (min-width: 1190px) and (max-width: 1250px) {
    max-width: 1100px !important;
  }
  @media (min-width: 1250px) and (max-width: 1350px) {
    max-width: 1200px !important;
  }
}
