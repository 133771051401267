$datepicker__font-family: "Poppins", sans-serif;
$datepicker__font-size: 1em;
$datepicker__selected-color: #96141d;

@import "~react-datepicker/src/stylesheets/datepicker.scss";

.react-datepicker__day {
  width: 2rem;
  line-height: 2rem;
  font-weight: 400;

  &:focus {
    outline: none;
  }
}

.react-datepicker__month-select,
.react-datepicker__year-select {
  padding: 5px 10px;
  margin: 5px 2px;
  border-radius: 5px;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  font-weight: 400;
}
