.upEvents {
  padding: 14px 16px 56px 16px;
  border-radius: 10px;
  background: #fff;
  .topNav {
    margin-bottom: 36px;
    border-radius: 10px;
    background: #fafafa;
    display: flex;
    flex-direction: row;
    align-items: center;
    h1 {
      margin-top: 6px;
      margin-left: 6px;
      color: #555;
      font-size: 14px;
      font-weight: 500;
      border-radius: 8px;
      background: #fff;
      padding: 6px 16px;
    }
    .arrowLeft {
      width: 19px;
      height: 19px;
      border-radius: 19px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 16px;
      margin-right: 30px;
    }
    .list {
      color: #555;
      font-size: 10px;
      span {
        margin-right: 34px;
      }
    }
    .arrowRight {
      width: 19px;
      height: 19px;
      border-radius: 19px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 6px;
    }
  }
}
