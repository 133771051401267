.dashPastEvents {
  border-radius: 10px;
  background: #fff;
  padding: 14px 16px 0 16px;
  .topNav {
    border-radius: 10px;
    background: #fafafa;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    h1 {
      margin-top: 6px;
      margin-left: 6px;
      color: #555;
      font-size: 14px;
      font-weight: 500;
      border-radius: 8px;
      background: #fff;
      padding: 6px 16px;
    }
    .arrows {
      display: flex;
      flex-direction: row;
      gap: 6px;
      .arrow {
        width: 19px;
        height: 19px;
        border-radius: 19px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 148px;
    img {
      margin-top: 88px;
    }
    h3 {
      color: #848484;
      font-size: 18px;
      font-weight: 600;
      margin-top: 30px;
      line-height: 20px; /* 111.111% */
    }
    p {
      color: #848484;
      text-align: center;
      font-size: 12px;
      margin-top: 11px;
    }
    span {
      border-radius: 35px;
      background: #fafafa;
      color: #323232;
      margin-top: 19px;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      padding: 16px 78px;
    }
  }
}
