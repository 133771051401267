.registerFormBigDiv {
  max-width: 671px;
  margin-left: auto;
  margin-right: auto;
  .registerSec {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .regNameSec {
      display: flex;
      flex-direction: row;
      .fNameSec {
        display: flex;
        flex-direction: column;
        label {
          font-weight: 600;
          margin-left: 20px;
        }
        .registerFormDiv {
          border-radius: 10px;
          background: #fff;
          .formRegCtrl {
            border-radius: 10px;
            border: 2px solid #c4c4c4;
            background: #fff;
            height: 50px;
            width: 100%;
            font-size: 14px;
            margin-right: 12px !important;
            font-weight: 500;
            padding-left: 20px;
            padding-right: 20px;
            &:focus {
              outline: none;
              border-radius: 10px;
              border: 2px solid #205076;
              background: #fff;
            }
          }
          .formRegCtrls {
            border-radius: 10px;
            border: 2px solid #c4c4c4;
            background: #fff;
            height: 50px;
            font-size: 14px;
            font-weight: 500;
            width: 100%;
            padding-left: 15px;
            padding-right: 20px;
            &:focus {
              outline: none;
              border-radius: 10px;
              border: 2px solid #205076;
              background: #fff;
            }
          }
          .passwordEye {
            margin-left: -48px;
            display: inline;
          }
          .errPass {
            width: 70%;
            display: none;
          }
        }
      }
    }
    .extFormReg {
      width: 90%;
      display: none;
    }
    .regTAC {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 50px;
      span {
        color: #000;
        a {
          color: #941c1f;
        }
      }
    }
    .buttonRegDiv {
      padding-bottom: 35px;
      .btnreg {
        border-radius: 10px;
        background: #941c1f;
        color: #fff;
        font-size: 16px;
        width: 495px;
        height: 54px;
        font-weight: 600;
        border: none;
        margin-top: 28px;
        width: 80%;
        margin-left: auto;
        display: block;
        margin-right: auto;
      }
    }
    .orRO {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 30px;
      hr {
        width: 130px;
        height: 0.5px;
        background: #848484;
      }
      small {
      }
    }
    .otherRegisterOptions {
      display: flex;
      flex-direction: row;
      gap: 24px;
      margin-bottom: 27px;
      margin-left: auto;
      margin-right: auto;
      button {
        padding: 9px;
        border-radius: 5px;
        border: 0.2px solid #000;
        background: #fff;
        .btnIcon {
        }
        &:hover {
          background: #205076;
          color: #fff;
        }
      }
      .btnFB {
      }
      .btnGoogle {
      }
      .btnApple {
      }
    }
    .RegisterCR {
      color: #969393;
      font-size: 14px;
      margin-bottom: 55px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .registerFormBigDiv {
    min-width: 390px !important;
    .registerSec {
      .regNameSec {
        gap: 20px;
        .fNameSec {
          .registerFormDiv {
            .formRegCtrl {
              width: 100%;
            }
            .passwordEye {
              display: none;
            }
            .formRegCtrls {
              width: 100%;
            }
          }
        }
      }
      .regTAC {
        margin-left: 0;
      }
      .buttonRegDiv {
        .btnreg {
          width: 100% !important;
        }
      }
      .orRO {
        hr {
          width: 90px;
        }
      }
      .otherRegisterOptions {
        button {
          font-size: 12px;
        }
      }
    }
  }
}

/* Media Queries (Small Devices) */
@media screen and (max-width: 600px) {
  .registerFormBigDiv {
    max-width: 425px !important;
    .registerSec {
      .regNameSec {
        gap: 20px;
        .fNameSec {
          .registerFormDiv {
            .formRegCtrl {
              width: 100%;
            }
            .formRegCtrls {
              width: 100%;
            }
          }
        }
      }
      .regTAC {
        margin-left: 0;
      }
      .buttonRegDiv {
        .btnreg {
          width: 100% !important;
        }
      }
      .orRO {
        hr {
          width: 90px;
        }
      }
      .otherRegisterOptions {
        button {
          font-size: 10px;
        }
        .btnFB {
        }
      }
    }
  }
}
@media screen and (max-width: 280px) {
  .registerFormBigDiv {
    min-width: 275px !important;
    .registerSec {
      .orRO {
        hr {
          width: 70px;
        }
      }
    }
  }
}
