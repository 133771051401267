.allRestMainHeader {
  .allRest {
    padding: 107px 96px;
    .allRestHead {
      display: flex;
      flex-direction: column;
      gap: 34px;
      h2 {
        color: #fff;
        font-size: 45px;
        font-weight: 600;
        line-height: 50px; /* 111.111% */
      }
      .allRestForm {
        display: flex;
        flex-direction: row;
        .allRestFormDiv {
          padding: 16px 190px 16px 22px;
          background: #fff;
          input {
            border: 0;
            outline: none;
            background: #fff;
            width: 160%;
          }
        }
        button {
          background: #9b763b;
          color: #fff;
          padding: 0px 53px;
          border: #9b763b;
        }
      }
    }
  }
  .restCardSec {
    padding: 64px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    margin-bottom: 64px;
  }
}
