* {
  font-family: "Poppins", sans-serif !important;
}
body {
  font-weight: 400 !important;
  font-size: 15px;
}
.text-base {
  font-size: 0.875rem;
}

.text-xs {
  font-size: 0.625rem;
}

.text-sm {
  font-size: 0.75rem;
}

.text-lg {
  font-size: 1rem;
}

.text-xl {
  font-size: 1.125rem;
}
.text-xxl {
  font-size: 1.5rem;
}
.text-2xl {
  font-size: 2rem;
}
.text-black,
.text-black a {
  color: #333333 !important;
}
.text-black-link > div > a:hover {
  color: #ffffff !important;
}

a {
  transition: linear color 100ms;
  transition-delay: 50ms;

  &:hover {
    text-decoration: none;
  }

  &.link-white {
    color: #fff;

    &:hover {
      color: #ccc;
    }
  }
}

.pointer {
  cursor: pointer;
}

.d-body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.pt-space {
  padding-top: 10vh;
}

.bg-fade {
  background: #fcfcfc !important;
}

.bg-cal {
  background: #ff512f;
  border: 1px solid #d9d9d9;
  padding: 18px 90px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.br-5 {
  border-radius: 5px;
}

.br-10 {
  border-radius: 10px;
}

.main-page-container--padding {
  padding-left: 60px;
  padding-right: 60px;

  @media (max-width: 992px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.order-page-container--padding {
  padding-left: 100px;
  padding-right: 100px;

  @media (max-width: 992px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.dashboard-wrapper {
  margin-left: 230px;
  // background-color: #f8f9fe;
  min-height: calc(100vh - 50px);
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  @media print {
    margin-left: 0;
  }
  .page-content {
    background-color: #f8f9fe;
    height: 100%;
  }

  footer {
    margin-left: 230px;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    footer {
      margin-left: 0 !important;
    }
  }

  table {
    padding: 0 !important;
    border-spacing: 0 11px !important;
    background-color: transparent !important;
    border-collapse: separate;
    thead.bg-blue {
      th {
        color: white;
      }
    }
    tr {
      box-shadow: 0 2px 4px rgba(#525252, 0.12);
      td {
        background-color: #fff;
      }
    }
  }
}

.w-20 {
  width: 20%;
}

.swae {
  border-radius: 25px !important;
}
.react-responsive-modal-modal {
  border-radius: 25px;
}
.btn-transparent {
  background: transparent;
  width: 100%;
}
.p-r {
  position: relative;
}

.bg-gray-3000 {
  background-color: #fafafa;
}

.event-category-label {
  position: absolute;
  top: 0;
  right: 0;
}

.cursor-pointer {
  cursor: pointer;
}
.bg-slight-gray {
  border: 1px solid #b5b4b4;
  background: #fafafa;
}
.h-vh {
  height: 100vh !important;
}
.react-skeleton-load {
  width: 100% !important;
}
.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.free {
  background-color: #008000;
  display: inline-block;
  border-radius: 4px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  padding: 0.55rem 0.8rem;
}
.rounded-cus-obs {
  border-radius: 50%;
}
.bg-brand-green {
  background-color: #13cc57 !important;
}
.bg-ev-cray {
  background: #c4c4c4;
}
.bg-ev-info {
  background: #f5f1f1;
  // margin-left: 20px;
}
.btn-chOut {
  background: #205076;
  border-radius: 8px;
  color: #fff;
  &:hover {
    color: #fff;
    background: #009d00;
  }
}
.ingresso-widget iframe {
  visibility: visible !important;
}
.colour-blue-brand {
  color: #205177;
}
.color-green-free {
  color: #009d00;
}
.bg-pink-fade {
  background-color: #e6d1d2;
}
.bg-input-nonEditable {
  background: #848484;
}
.bg-orangy-fade {
  background: linear-gradient(90deg, #ff512f 0%, #dd2476 100%);
}
.bg-brand-pink {
  background-color: #f9414d;
}
.font-weight-medium {
  font-weight: 500 !important;
}
.btn-secondary {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-modal-login {
  background: #ff5e62;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}
.btn-brand,
.btn-brand:hover {
  color: #fff;
  background-color: #205177;
  border-color: #205177;
}
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #96141c;
  background-color: #96141c;
}
.timer {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #eb5757;
}
.bg-gray-t7 {
  background-color: #fcfcfc;
  border: 1px solid #e9e9e9;
}
.border-width-2 {
  border-width: 2px !important;
}
.gray-color {
  color: #999999;
}
.max-w-400 {
  max-width: 500px;
}
.calendar-arrow {
  font-size: 35px;
  color: #205177;
}
.active-calendar {
  border-bottom: 2px solid #205177;
}
.important-link {
  color: #96141c;
  text-decoration: underline;
}

.w-md-100 {
  @media (max-width: 768px) {
    width: 100% !important;
  }
}

.flex-md-column {
  flex-direction: row !important;

  @media (max-width: 768px) {
    flex-direction: column !important;
  }
}
.form-control {
  font-weight: 400 !important;
}
.form-text {
  color: #495055;
  font-weight: 400;
  padding: 0.56rem 0.75rem;
  border-right: 0px !important;
  margin-top: 0px;
}
.no-border {
  border: none;
}
#verifyPinModal {
  .pin-input {
    width: 57.26px;
    height: 55px;
    text-align: center;
    font-weight: 400;
  }
}

/* The check-container */
.check-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: transparent;
    border: #cfcfdf solid 1px;
    border-radius: 3px;
  }
  tr td .checkmark {
    top: 0;
    left: 16px;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: #205177;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid #205177;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.file-picker {
  display: flex;
  justify-content: space-between;
  color: #6c757d;
  width: 100%;
  height: 38px;
  background: #ffffff;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  padding-left: 11px;
  align-items: center;
  .browse-btn {
    background: #f8f9fa;
    border-left: 1px solid #ced4da;
    box-sizing: border-box;
    border-radius: 0px 4px 4px 0px;
    color: black;
    cursor: pointer;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 11px;
  }
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.w-fit-content {
  width: fit-content !important;
}

.react-responsive-modal-root {
  z-index: 2000 !important;
}

input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: transparent;
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  width: 1.15em;
  height: 1.15em;
  border: 1px solid;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;

  &::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    // box-shadow: inset 1em 1em #2D527C;
    /* Windows High Contrast Mode */
    // background-color: CanvasText;
  }

  &:checked::before {
    transform: scale(1);
  }

  &:checked {
    border: 4px solid #2d527c;
  }

  &:focus {
    outline: max(1px, 0.1em) solid #2d527c;
    outline-offset: max(1px, 0.1em);
  }
}

.custom-checkbox {
  input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: transparent;
    /* Not removed via appearance */
    margin: 0;

    font: inherit;
    color: #2d527c;
    width: 1em;
    height: 1em;
    border: 0.15em solid #2d527c;
    border-radius: 0.15em;
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;

    &::before {
      content: "";
      width: 0.65em;
      height: 0.65em;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
      transform: scale(0);
      transform-origin: bottom left;
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em #2d527c;
      /* Windows High Contrast Mode */
      background-color: CanvasText;
    }

    &:checked::before {
      transform: scale(1);
    }

    &:focus {
      outline: max(1.5px, 0.09em) solid #2d527c;
      outline-offset: max(1.5px, 0.09em);
    }

    &:disabled {
      // --form-control-color: var(--form-control-disabled);

      // color: var(--form-control-disabled);
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.dashboard-sidebar-wrapper,
.event-dash-sidebar-wrapper {
  .sidebar-overlay {
    .closeMenu {
      display: none;
      padding: 0.5rem 1rem;
      color: white;
      cursor: pointer;
      font-size: 3rem;
      position: absolute;
      right: 0;
      opacity: 0.5;
    }
    @media (max-width: 768px) {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 0;
      background-color: rgba(black, 0.8);
      &.overlay-isOpen {
        width: 100%;
        z-index: 0;
        .closeMenu {
          display: block;
        }
      }
    }
  }
}
.btn-radius {
  border-radius: 30px;
}
.rounded-input {
  border-radius: 30px;
}

.selection-box {
  background-color: #dfdfdf;
  min-width: 90px;
  border-radius: 5px;
  padding: 5px;
  margin-right: 6px;
  margin-block: 6px;
  height: 40px;
  display: flex;
  align-items: center;
  .input,
  .select {
    min-width: 90px;
    color: #434343;
    font-weight: 600;
    font-size: 14px;
    border: 0;
    background: transparent;
    &:focus {
      outline: none;
      border: 0;
    }
  }
}
