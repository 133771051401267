.event-card-component {
  margin-bottom: 15px;

  .h-100 {
    border-top-right-radius: 20px !important;
    border-top-left-radius: 20px !important;
    border: none;
    .card-link {
      // min-height: 393px;
      .card-body {
        padding-top: 0;
      }
    }
    .card-image-container {
      overflow: hidden;
      max-height: 250px;
      display: flex;
      align-items: center;
      border-top-right-radius: 20px !important;
      border-top-left-radius: 20px !important;

      .card-image {
        height: 100%;
        min-height: 250px;
        width: 100%;
        object-fit: 100% 100%;
        transition: transform 500ms ease-out;
      }
    }

    .event-category-label {
      position: absolute;
      top: 15px;
      left: 15px;
      right: 60%;
      background: transparent;
      width: 100%;
      span {
        width: 100%;
        border-radius: 6px;
      }
    }

    .card-content-one {
      padding-top: 15px;
      padding-bottom: 10px;
    }

    .address-span {
      font-size: 12px;
    }

    .card-c-t-c {
      button {
        background: transparent;
        color: #323232;
        width: 13px;
      }
    }

    .price-div-ctc {
      .price-span {
        font-weight: 600;
      }
    }

    .event-category-icons {
      position: absolute;
      top: 5px;
      right: 15px;
      display: flex;
      flex-direction: row;
      gap: 0.7rem;
      .event-icons {
        background-color: transparent;
        .btn-round {
          border: none;
          padding: 6px 8px;
        }
      }
    }

    .card-button {
      background: #96141c;
      border-color: #96141c;
    }

    .event-address {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .event-name {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &:hover {
      .card-image {
        transform: scale(1.2);
        opacity: 0.7;
      }
    }
    .card-footer {
      position: relative;
      border-top: 0 !important;
    }
    .popup-div {
      visibility: hidden;
      min-width: 305px;
      background-color: #555;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 8px 8px;
      position: absolute;
      z-index: 1;
      bottom: 84%;
      margin-left: -89px;
      left: 115px;
      margin-top: -40px;
      &.show {
        visibility: visible;
        -webkit-animation: fadeIn 1s;
        animation: fadeIn 1s;
      }
      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 37%;
        border-width: 6px;
        border-style: solid;
        border-color: #555 transparent transparent transparent;
      }
      .social-icons {
        background-color: #fff;
        margin-right: 10px;
        box-shadow: 0 0 5px 0 #ccc;
        width: 50px;
        height: 50px;
        line-height: 50px;
        border-radius: 50px;
        font-size: 18px;
        color: #96141c;
        text-align: center;
        cursor: pointer;
        @media (max-width: 1100px) {
          width: 45px;
          height: 45px;
        }
      }
    }
    .btn-link {
      &.text-red {
        color: #ff0000ad !important;
      }
    }
  }
}
