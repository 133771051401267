.dashboard-sidebar-wrapper {
  #sidebar-wrapper {
    top: 51px;
    left: -230px;
    width: 230px;
    position: fixed;
    height: calc(100% - 50px);
    z-index: 1;
    background: white;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    @media (max-width: 768px) {
      &.active {
        left: 230px;
      }
    }
    &.sidebar-toggle {
      transition: all 0.4s ease-out;
      margin-left: -230px;
      @media (min-width: 768px) {
        left: 230px !important;
      }
    }
    .sidebar-nav {
      position: absolute;
      top: 0;
      margin: 0;
      padding: 0;
      width: 230px;
      height: 100%;
      list-style: none;

      li {
        border-bottom: #ddd solid 1px;
        a,
        .parent-link {
          text-decoration: none;
          text-transform: capitalize;
          padding: 15px 15px 15px 25px;
          display: inline-block;
          width: 100%;
          color: #333;
          font-size: 14px;
          font-weight: 400;
          transition: all ease 0.5s;
          &:hover {
            color: #97141d;
            text-decoration: none;
          }
          &:active,
          &:focus,
          &.active {
            text-decoration: none;
            color: #215177 !important;
            font-weight: 500;
          }
        }
        .parent-link {
          border-left: transparent solid 3px;
          transition: all ease 0.5s;
          cursor: pointer;
          &:hover {
            border-left: #97141d solid 3px;
          }
        }
        .sub-links {
          padding-left: 1rem;
          display: none;
          transition: height 0.5s;
          a {
            padding: 5px 15px 5px 25px;
          }
          &.show {
            display: block;
          }
        }
      }
    }
  }
}
