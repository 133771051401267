.dashTrans {
  border-radius: 10px;
  background: #fff;
  height: 100vh;
  h1 {
    padding-top: 30px;
    margin-left: 34px;
    color: #555;
    font-size: 14px;
    font-weight: 500;
  }
  .recentTrans {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50%;
    img {
      width: 90px;
      height: 82.176px;
      margin-bottom: 40px;
    }
    h3 {
      color: #848484;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px; /* 100% */
      margin-bottom: 11px;
    }
    span {
      color: #848484;
      text-align: center;
      font-size: 10px;
      font-weight: 500;
      margin-bottom: 40px;
    }
    p {
      border-radius: 35px;
      background: #fafafa;
      color: #323232;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      padding: 14px 65px;
    }
  }
}
