.alternate-navbar {
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 30%);
  @media (max-width: 768px) {
    flex-wrap: nowrap;
  }

  .navbar-nav {
    & > .nav-item {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      & > .nav-link {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1rem;
        @media (max-width: 990px) {
          font-size: 12px;
        }
      }

      .dropdown-toggle {
        &::after {
          display: none;
        }
      }

      .dropdown-menu {
        border-radius: 0;
        margin: -2px 0 0 0;

        & > .dropdown-item {
          min-width: 15rem;
          padding: 10px 20px;
          transition: all 100ms ease-in 10ms;
          color: #000;

          &:hover {
            color: #ffffff;
            background-color: #96141c;
          }
        }
      }
    }
  }

  .toggle-logo-wrapper {
    #menu-toggle {
      display: none;
    }
    @media (max-width: 768px) {
      #menu-toggle {
        display: block;
        font-size: 20px;
        margin-left: 20px;
        .navbar {
          flex-wrap: nowrap;
        }
      }
      .navbar {
        flex-wrap: nowrap;
      }
      .navbar-brand {
        img {
          width: 100px !important;
        }
      }
    }
  }
}
