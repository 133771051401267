.login-page--form {
  .form-control {
    //border-width: 2px;

    &:focus {
      border-color: #245177;
    }
  }

  .social-auth-buttons {
    button {
      margin: 10px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &.facebook-button {
        border: solid 1px #3b5997;
        color: #3b5997;

        &:hover {
          color: #ffffff;
          background-color: #3b5997;
        }
      }

      &.google-button {
        border: solid 1px #de4c38;
        color: #de4c38;

        &:hover {
          color: #ffffff;
          background-color: #de4c38;
        }
      }
    }
  }
}

.lgtxt {
  font-size: 16px;
  line-height: 23px;
  color: #848484;
  // margin-left: 75px !important;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 16px;
  width: 48%;
  margin-bottom: 50px;
  display: block !important;
}

.form-control-log--mod {
  background: #ffffff !important;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  width: 100%;
  border: none;
  &:focus {
    outline: none !important;
  }
}

.loginPage {
  min-width: 100vw;
  .loginPageContainer {
    .loginPageRow {
      .loginLeftColumn {
        zoom: 0.8;
        display: flex;
        flex-direction: column;
        .logo {
          padding-top: 44px;
          margin: 0 auto;
          cursor: pointer;
        }
        .head {
          margin: 30px auto;
          font-size: 20px;
          font-weight: 700;
          line-height: normal;
          span {
            color: #037893;
          }
        }
        .loginInPageNav {
          margin: 0 auto;
          display: flex;
          flex-direction: row;
          gap: 68px;
          a {
            cursor: pointer;
            color: #555;
          }
          a.active {
            color: #1b1b1b;
            border-bottom: 2px solid #1b1b1b;
            padding-bottom: 5px;
            padding-left: 5px;
            padding-right: 5px;
          }
        }
        .inPageNavSelect {
          margin-top: 40px;
          .inPageLoginSection {
            span {
              color: #555;
              font-size: 14px;
              text-align: center;
              margin: 0 auto;
              display: block;
            }
            .loginFormBigDiv {
              max-width: 495px !important;
              margin-left: auto;
              margin-right: auto;
              .loginEmailSec {
                label {
                  font-weight: 600;
                  margin-left: 28px;
                  margin-bottom: 12px;
                  color: #000;
                }
                .loginFormDiv {
                  border-radius: 10px;
                  background: #fff;
                  .formLoginCtrl {
                    border-radius: 10px;
                    border: 2px solid #c4c4c4;
                    background: #fff;
                    width: 100%;
                    height: 50px;
                    padding-left: 28px;
                    &:focus {
                      outline: none;
                      border-radius: 10px;
                      border: 2px solid #205076;
                      background: #fff;
                    }
                  }
                }
              }
              .loginPassSec {
                label {
                  font-weight: 600;
                  margin-left: 28px;
                  margin-bottom: 12px;
                }
                .loginFormDiv {
                  border-radius: 10px;
                  background: #fff;
                  .formLoginCtrl {
                    border-radius: 10px;
                    border: 2px solid #c4c4c4;
                    background: #fff;
                    width: 100%;
                    height: 50px;
                    padding-left: 28px;
                    &:focus {
                      outline: none;
                      border-radius: 10px;
                      border: 2px solid #205076;
                      background: #fff;
                    }
                  }
                }
              }
              .loginRMFP {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                .loginRememberMe {
                  input {
                    width: 13px;
                    height: 13px;
                  }
                  label {
                    margin-left: 6px;
                    color: #000;
                    font-weight: 600;
                  }
                }
                .loginForgotPassword {
                  color: #000;
                  .fgtpss {
                    color: #000;
                  }
                }
              }
              .loginBtnDiv {
                padding-bottom: 41px;
                width: 100%;
                .loginBtn {
                  border-radius: 10px;
                  width: 100%;
                  background: #941c1f;
                  color: #fff;
                  font-size: 16px;
                  font-weight: 600;
                  padding: 15px 200px;
                  border: none;
                  margin-top: 28px;
                  white-space: nowrap;
                }
              }
              .orSIO {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                margin-bottom: 44px;
                hr {
                  width: 130px;
                  height: 0.5px;
                  background: #848484;
                }
              }
              .otherSignInOptions {
                display: flex;
                flex-direction: row;
                gap: 24px;
                margin-bottom: 27px;
                align-items: center;
                justify-content: center;
                width: 100%;
                button {
                  padding: 9px;
                  border-radius: 5px;
                  border: 0.2px solid #555;
                  background: #fff;
                  &:hover {
                    background: #205076;
                    color: #fff;
                  }
                }
              }
              .signInCR {
                color: #969393;
                font-size: 14px;
                margin-bottom: 55px;
              }
            }
          }
          .inPageRegisterSection {
            .inPageRegisterSectionSpan {
              color: #555;
              text-align: center;
              width: 100%;
              font-size: 14px;
            }
          }
        }
      }
      .loginRightColumn {
        min-height: 100vh;
        padding-right: 0 !important;
        padding-left: 0 !important;
        .loginSlider {
          zoom: 1;
          .slick-dots {
            bottom: 3vh;
            left: -320px;
            li {
              button {
                &::before {
                  content: "";
                  background-color: #fff;
                  width: 18px;
                  height: 18px;
                  border-radius: 18px;
                  opacity: 1;
                }
              }
              &.slick-active {
                button {
                  &::before {
                    background: transparent;
                    border: 2px solid #fff;
                  }
                }
              }
            }
          }
          .lsArticle {
            .images {
              min-height: 1120px;
              min-height: 150vh;
            }
          }
        }

        .talkToUsLR {
          position: fixed;
          bottom: 0.25px;
          right: 22px;
          button {
            border-radius: 20px 20px 0px 0px;
            border: none;
            background: #fff;
            padding: 14px 50px;
            width: 275px;
            height: 55px;
            display: flex;
            flex-direction: row;
            gap: 24px;
            .ttuIcon {
              color: #941c1f;
              margin-top: 4px;
            }
            span {
              color: #205076;
              font-size: 22px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .loginPage {
    .loginLeftColumn {
      align-items: center;
      justify-content: center;
      .inPageNavSelect {
        .inPageLoginSection {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-left: auto;
          margin-right: auto;
          span {
            margin-left: 0;
          }
          .loginFormBigDiv {
            padding-left: 0 !important;
            padding-right: 0 !important;
            .loginPassSec {
              .loginFormDiv {
                .formLoginCtrl {
                  width: 100%;
                }
              }
            }
            .loginEmailSec {
              .loginFormDiv {
                .formLoginCtrl {
                  width: 100%;
                }
              }
            }
            .orSIO {
              small {
                font-size: 10px;
              }
            }
            .otherSignInOptions {
              button {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
    .loginRightColumn {
      display: none;
    }
  }
}

/* Media Queries (Small Devices) */
@media screen and (max-width: 600px) {
  .loginPage {
    // margin: 0 32px;
    .loginLeftColumn {
      .inPageNavSelect {
        .inPageLoginSection {
          .loginFormBigDiv {
            max-width: 405px !important;
            min-width: 360px !important;
            .loginPassSec {
              .loginFormDiv {
                .formLoginCtrl {
                  width: 100%;
                }
              }
            }
            .loginEmailSec {
              .loginFormDiv {
                .formLoginCtrl {
                  width: 100%;
                }
              }
            }
            .loginBtnDiv {
              .loginBtn {
                padding: 15px 0 !important;
              }
            }
            .orSIO {
              hr {
                width: 90px !important;
              }
            }
            .otherSignInOptions {
              button {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 375px) {
  .loginPage {
    .loginPageContainer {
      .loginPageRow {
        .loginLeftColumn {
          .head {
            font-size: 22px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 280px) {
  .loginPage {
    .loginLeftColumn {
      .inPageNavSelect {
        .inPageLoginSection {
          .loginFormBigDiv {
            min-width: 275px !important;
            .orSIO {
              hr {
                width: 70px !important;
              }
            }
            .loginRMFP {
              .loginRememberMe {
                display: flex;
                align-items: center;
                justify-content: center;
                label {
                  font-size: 8px !important;
                  margin-bottom: 0 !important;
                }
              }
              .loginForgotPassword {
                margin-top: -5px !important;
                .fgtpss {
                  font-size: 8px !important;
                }
              }
            }
          }
        }
      }
    }
    .loginPageContainer {
      .loginPageRow {
        .loginLeftColumn {
          .head {
            font-size: 20px;
          }
        }
      }
    }
  }
}
